<template>
 <v-card :loading="loading" class="mx-auto my-12"  max-width="374" style="background-color:honeydew">
    <template slot="progress">
      <v-progress-linear  color="deep-purple"  height="10"  indeterminate></v-progress-linear>
    </template>

    <!-- <v-img
      height="250"
      src="https://cdn.vuetifyjs.com/images/cards/cooking.png"
    ></v-img> -->

    <v-card-title >
      <v-btn color="primary ml-1" style="font-size: small; padding:1px"> ژ: {{item.id}}  </v-btn>
      <v-btn color="primary ml-1" style="font-size: small; float: left;padding:1px">  کریار:  {{item.customerName}}  </v-btn>
      <v-btn color="primary" style="font-size: small; float: center;padding:1px"> موبایلی : {{item.mobile}}   </v-btn>
    
    </v-card-title>

    <v-card-text>
      <v-row
        align="center"
        class="mx-0"
      >
        <!-- <v-rating
          :value="4.5"
          color="amber"
          dense
          half-increments
          readonly
          size="14"
        ></v-rating> -->

        <div  class="grey--text ms-4">
          <v-btn color="error" style="font-size: small;"> پارە :{{item.amount}} دينار  </v-btn>
          <v-btn class="ml-1" v-show="comission" normal style="font-size: small;"> <span > +{{item.commission}} دينار </span>  </v-btn>
         
        
        </div>
      </v-row>

      <div class="my-4">
        <v-btn  style="font-size: small;width: -webkit-fill-available"> ناونیشان: {{item.city}} - {{item.address}}  </v-btn>
       
      </div>

      <!-- <div>Small plates, salads & sandwiches - an intimate setting with 12 indoor seats plus patio seating.</div> -->
    </v-card-text>

    <v-divider class="mx-4"></v-divider>

    <v-card-title>
      <v-btn class="ml-1">  شۆفير: {{item.driverName}} </v-btn>
      <v-btn class="ml-1"> موبايلی {{item.driverMobile}} </v-btn>
     

    </v-card-title>

    <v-card-text v-show="isAdmin || isDriver">
      <v-chip-group
        v-model="item.status"
        active-class="deep-purple accent-4 white--text"
        column
      >
        <v-chip @click="selectMe(item.id, 0)">نوي</v-chip>

        <v-chip @click="selectMe(item.id, 3)">وەرمگرت</v-chip>

        <v-chip @click="selectMe(item.id, 4)">رفض</v-chip>

        <v-chip @click="selectMe(item.id, 5)">مؤجل</v-chip>
      </v-chip-group>
    </v-card-text>

    <v-row>  
    <v-col> <v-btn> {{formatDate(item.deliveryDat)}}  </v-btn> </v-col>
     <v-spacer></v-spacer>
    <v-col>  <v-btn  icon color="green" v-show="isitRejedtedAndReady">
              <v-icon>mdi-thumb-up</v-icon>
             </v-btn>
    </v-col>
    
     <v-spacer></v-spacer>
     <v-col>  <v-btn style="">  {{statusOfproduct}} </v-btn> </v-col>
   </v-row>
    <v-row>
       <v-col  cols="7">  
           <v-text-field
            v-model="item.packageName"
            append-outer-icon= 'mdi-send'
           style="width:auto"
            label="تیبینی"
            type="text"
            
            @click:append-outer="sendMessage"
            
           
          ></v-text-field>
    </v-col>  
    <v-col>
      <router-link v-if="isAdmin" :to="{ name: 'Transport', params: { id: this.item.id }}">
        <v-icon color="blue"  large  class="mr-2" > mdi-pencil  </v-icon>
      </router-link>
      
    </v-col>
   
    <v-col >
        <v-btn
    v-show="isAdmin"
      class="mx-2"
      fab
      dark
      small
      color="error"
      @click="removeOrderFunction"
    >
      <v-icon dark>
        mdi-minus
      </v-icon>
    </v-btn>
    </v-col>
    </v-row>
    
    <!-- <v-card-actions>
      <v-btn
        color="deep-purple lighten-2"
        text
        @click="reserve"
      >
        Reserve
      </v-btn>
    </v-card-actions> -->
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex'
  export default {
    data: () => ({
      loading: false,
       note: '',
      //isActive: true
      //selection: 0,
    }),
    props: {
      item: Object,
      buttons: {
        type: Boolean,
        default: false,
      } ,
      comission: {
        type: Boolean,
        default: false,
      }
    },

    methods: {
      ...mapActions(['test', 'updateStatus', 'reomveOrder', 'deleteOrder', 'updateNote']),
      selectMe(id, status) {
       if(confirm('دلنياي?')) {
          this.updateStatus({id: id, status:status})
          //console.log('sorry');
       }
        
         //console.log("updated");
      },
      sendMessage () {
        this.updateNote({'id': this.item.id, 'note': this.item.packageName})
      },
      clearMessage () {
        this.message = ''
      },
      reserve () {
        this.loading = true

        //setTimeout(() => (this.loading = false), 2000)
      },
      deleteAdmin() {
        //this.deleteOrder(this.item.id)
      },
      removeOrderFunction() {
        this.reomveOrder(this.item.id)
         // console.log(this.item.id);
      }, 
      formatDate(theDate) {
        //console.log(theDate);
        return theDate==null? '': new Date(theDate).toLocaleDateString("en-US")
      }
    },
    computed: {
      ...mapState(['userId', 'userType']),
      isActive () {
        return this.item.status == 4
      },
      isAdmin() {
        return this.userType=='admin'
      },
      isDriver() {
        return this.userType=='driver'
      },
      isitRejedtedAndReady() {
        return this.item.status==4 && this.item.deliveryDat != null && this.item.driverClearnce
        
      },
      statusOfproduct() {
        if (this.item.status == 0 )
          return 'new'
        else if (this.item.status ==1 ) 
          return 'shipped'
        else if (this.item.status == 2) 
          return 'Arrived'
        else if (this.item.status == 3)
          return 'paid'
        else if (this.item.status == 4)
          return 'rejected'
        else if (this.item.status == 5)
          return 'pending'
        else if (this.item.status == 6)
          return 'ready'
        else return ''
      }
    }
  }
</script>

<style scoped>
.statusRejected {
  background-color: brown;
}
</style>

